import React from "react";
import styled from "styled-components";
import {
  PageSection,
  PageSectionArticle,
  PageSectionBackdrop,
} from "../common";

const FaqList = styled.ul`
  list-style: none;
  max-width: 980px;
`;

const FaqListItem = styled.li`
  margin-bottom: 24px;

  h3 {
    margin-bottom: 16px;
  }
`;

export const GetStartedPageFaq = ({ getStartedFaq }) => {
  const { heading, getStartedFaqList } = getStartedFaq;
  return (
    <PageSection bgColor="var(--creamWhite)">
      <PageSectionBackdrop bgColor="var(--creamWhite)">
        <PageSectionArticle>
          <h2>{heading}</h2>
          <FaqList>
            {!!getStartedFaqList &&
              getStartedFaqList.map(({ question, answer }, i) => (
                <FaqListItem key={i}>
                  <h3>{question}</h3>
                  <p>{answer}</p>
                </FaqListItem>
              ))}
          </FaqList>
        </PageSectionArticle>
      </PageSectionBackdrop>
    </PageSection>
  );
};
