import styled from "styled-components";
import { CustomButton } from "../common/CustomButton";

export const SubscriptionFormEl = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.marginBottom ? "100px" : "40px")};

  h3 {
    color: var(--oceanGreen);
    margin-bottom: 30px;
  }

  ${CustomButton} {
    align-self: flex-end;
  }
`;
