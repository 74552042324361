import React from "react";
import styled from "styled-components";
import { respondTo } from "../../styles";
import {
  PageSection,
  PageSectionArticle,
  PageSectionBackdrop,
} from "../common";

const IncludedWrapper = styled.div`
  background-color: var(--snotGreen);
  padding: 23px 35px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${respondTo.lg`
    flex-direction: row;
    margin-bottom: 50px;
  `}
`;
const IncludedBox = styled.div`
  width: 100%;

  h3 {
    margin-bottom: 16px;
  }

  &:not(:last-child) {
    margin-bottom: 35px;
  }

  ${respondTo.lg`
    width: calc(100% / 3);
    
    &:not(:last-child) {
      padding-right: 35px;
      margin-bottom: 0;
    }
  `}
`;
export const GetStartedPageIncluded = ({ getStartedIncluded }) => {
  return (
    <PageSection bgColor="var(--creamWhite)">
      <PageSectionBackdrop bgColor="var(--creamWhite)">
        <PageSectionArticle>
          <IncludedWrapper>
            {!!getStartedIncluded &&
              getStartedIncluded.map(({ heading, text }, i) => (
                <IncludedBox key={i}>
                  <h3>{heading}</h3>
                  <p>{text}</p>
                </IncludedBox>
              ))}
          </IncludedWrapper>
        </PageSectionArticle>
      </PageSectionBackdrop>
    </PageSection>
  );
};
