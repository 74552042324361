import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import {
  ImageWrapperSquare,
  PageSection,
  PageSectionBackdrop,
  PageSectionHeader,
  PageSectionHeaderText,
  TextEditor,
} from "../common";
import { PageHeaderImage } from "../common/PageHeaderImage";
import { toHTML } from "../../utils/helpers/index";
import { respondTo } from "../../styles";

const ImageBox = styled.div`
  border-radius: 10px;
`;

const ListBox = styled.div`
  margin-bottom: 30px;

  ${respondTo.lg`
    margin-bottom: 0;
  `}

  h3 {
    margin-bottom: 20px;
  }
`;

const HeaderTextWrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;

  ${respondTo.lg`
    grid-template-columns: 45% 55%;
    grid-template-rows: auto auto;
    grid-column-gap: 30px;
  `}
`;

const GetStartedPageSectionHeader = styled(PageSectionHeader)`
  h1 {
    margin-bottom: 50px;
  }
`;
export const GetStartedPageHeader = ({ getStartedHeader }) => {
  return (
    <PageSection bgColor="var(--creamWhite)">
      <PageSectionBackdrop bgColor="var(--creamWhite)">
        <GetStartedPageSectionHeader>
          <PageHeaderImage />
          <PageSectionHeaderText>
            <h1>{getStartedHeader.heading}</h1>
            <HeaderTextWrapper>
              <ListBox>
                <h3>{getStartedHeader.listHeading}:</h3>
                <TextEditor
                  dangerouslySetInnerHTML={{
                    __html: toHTML(getStartedHeader.body),
                  }}
                />
              </ListBox>

              <ImageBox>
                <ImageWrapperSquare size="280px">
                  <Img
                    fluid={getStartedHeader.image.childImageSharp.fluid}
                    alt={getStartedHeader.altText}
                  />
                </ImageWrapperSquare>
              </ImageBox>
            </HeaderTextWrapper>
          </PageSectionHeaderText>
        </GetStartedPageSectionHeader>
      </PageSectionBackdrop>
    </PageSection>
  );
};
