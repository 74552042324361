import React from "react";
import styled from "styled-components";

const InvoiceOptionContainer = styled.div`
    width: 300px;
    background-color: var(--deepBlue);
    padding: 12px;
    color: white;
    margin: 0 0 100px auto;
    box-shadow: 12px 11px 8px rgba(0, 0, 0, 0.1);
    border-radius: 67px;
    align-items: center;
    justify-content: center;


    // p {
    //   font-weight: 700;
    //   line-height: 19px;
    // }

    a {
        color: white;
        font-size: 22px;
        font-weight: 700;
        line-height: 22px;
        text-decoration: none;
        text-align: center;
    }
`;
export const InvoiceOption = ({ link }) => {
return (
    <InvoiceOptionContainer>
    <a align="center" href={link} target="_blank" rel="noopener noreferrer">
        Beställ via LäroMedia
    </a>
    </InvoiceOptionContainer>
);
};
