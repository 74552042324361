import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import { PageSectionWrapper } from "../components/common";
import {
//  GetStartedPageHeader,
  GetStartedPageIncluded,
  GetStartedPageSubscription,
} from "../components/GetStartedPage";
import { GetStartedPageFaq } from "../components/GetStartedPage/GetStartedPageFaq";

export const GetStartedPageTemplate = ({
  title,
//  getStartedHeader,
//  getStartedSubscriptionDetails,
  getStartedSubscription,
  getStartedIncluded,
  getStartedFaq,
}) => {
  return (
    <>
      <SEO title={title} />
      <PageSectionWrapper bgColor="var(--creamWhite)">
        <GetStartedPageSubscription
//          getStartedSubscriptionDetails={getStartedSubscriptionDetails}
          getStartedSubscription={getStartedSubscription}
        />
        <GetStartedPageIncluded getStartedIncluded={getStartedIncluded} />
        <GetStartedPageFaq getStartedFaq={getStartedFaq} />
      </PageSectionWrapper>
    </>
  );
};

const GetStartedPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <GetStartedPageTemplate
      title={frontmatter.title}
//      getStartedHeader={frontmatter.getStartedHeader}
//      getStartedSubscriptionDetails={frontmatter.getStartedSubscriptionDetails}
      getStartedSubscription={frontmatter.getStartedSubscription}
      getStartedIncluded={frontmatter.getStartedIncluded}
      getStartedFaq={frontmatter.getStartedFaq}
    />
  );
};

export default GetStartedPage;

export const getStartedPageQuery = graphql`
  query GetStartedPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        getStartedSubscription {
          heading
          text
          invoiceOption
          invoiceLink
          prenimageHeading
          prentext
          prenimage {
              publicURL
              name
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          printimageHeading
          printtext
          printimage {
              publicURL
              name
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          parentimageHeading
          parenttext
          parentimage {
              publicURL
              name
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }
        getStartedIncluded {
          heading
          text
        }
        getStartedFaq {
          heading
          getStartedFaqList {
            question
            answer
          }
        }
      }
    }
  }
`;
