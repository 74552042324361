import React from "react";
import styled from "styled-components";
import {
  PageSection,
  PageSectionArticle,
  PageSectionBackdrop,
} from "../common";
import { ImageWrapperSub } from "../common/ImageWrapper";
import SubscriptionForm from "../../components/SubscriptionForm";
import { InvoiceOption } from "./InvoiceOption";
import Img from "gatsby-image";

const TextContainer = styled.div`
  margin-bottom: 32px;
  max-width: 730px;
`;

const TextContainerFst = styled.div`
  margin-bottom: 64px;
  max-width: 730px;
`;

export const GetStartedPageSubscription = ({
  getStartedSubscription,
//  getStartedSubscriptionDetails,
}) => {
  const { 
    heading, 
    text, 
    invoiceOption, 
    invoiceLink, 
    prenimageHeading, 
    prentext, 
    prenimage, 
    printimageHeading, 
    printtext, 
    printimage, 
    parentimageHeading, 
    parenttext, 
    parentimage, 
  } = getStartedSubscription;

  const { GATSBY_KLARNA_ENABLED } = process.env;

  return (
    <PageSection bgColor="var(--creamWhite)">
      <PageSectionBackdrop bgColor="var(--creamWhite)">
        <PageSectionArticle>
          <TextContainerFst>
            <ImageWrapperSub> {' '} </ImageWrapperSub>
          </TextContainerFst>
          
          <TextContainer>             
            <h2>{heading}</h2>
            <p>{text}</p>
          </TextContainer>
          <TextContainer>
            <h3 color="var(--oceanGreen)">{prenimageHeading}</h3>
            <p>{prentext}</p>
            <ImageWrapperSub>
              <Img fluid={prenimage.childImageSharp.fluid} />
            </ImageWrapperSub>
          </TextContainer>
          <TextContainer>
            <h3 color="var(--oceanGreen)">{printimageHeading}</h3>
            <p>{printtext}</p>
            <ImageWrapperSub>
              <Img fluid={printimage.childImageSharp.fluid} />
            </ImageWrapperSub>
          </TextContainer>

          <TextContainer>
          {invoiceOption && <InvoiceOption link={invoiceLink} />}
          </TextContainer>

          <TextContainer>
            <h3 color="var(--oceanGreen)">{parentimageHeading}</h3>
            <p>{parenttext}</p>
            <ImageWrapperSub>
              <Img fluid={parentimage.childImageSharp.fluid} />
            </ImageWrapperSub>
          </TextContainer>
          <TextContainer>
          {GATSBY_KLARNA_ENABLED && JSON.parse(GATSBY_KLARNA_ENABLED) && (
            <SubscriptionForm
//              getStartedSubscriptionDetails={getStartedSubscriptionDetails}
              marginBottom={!invoiceOption}
            />
          )}          
          </TextContainer>

        </PageSectionArticle>
      </PageSectionBackdrop>
    </PageSection>
  );
};
