import React, { useState, useEffect } from "react";
import { CustomButton } from "../common";
//import CustomRadioAndCheckbox from "../common/CustomRadioAndCheckbox";
import { 
  //ProductOption, 
  //ProductOptionNew, 
  ProductOptions 
        } from "./ProductOptions";
import { SubscriptionFormEl } from "./SubscriptionFormEl";

import { navigate } from "gatsby";

const SubscriptionForm = ({ marginBottom }) => {
  const [subscriptionFormValues, setSubscriptionFormValues] = useState({
    subscription: "parent",
    cardAmount: 0,
  });

  //const [addCards, setAddCards] = useState(false);


  useEffect(() => {
    setSubscriptionFormValues((currentValue) => ({
      ...currentValue,
      subscription: "parent",
    }));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { subscription, cardAmount } = subscriptionFormValues;
    navigate(`/checkout?subscription=${subscription}&cardAmount=${cardAmount}`);
  };

  return (
    <SubscriptionFormEl marginBottom={marginBottom} onSubmit={handleSubmit}>
      <CustomButton
        type="submit"
        borderColor={"var(--creamWhite)"}
        textColor={"var(--creamWhite)"}
        bgColor={"var(--buttonBg)"}
        customWidth="300px"
      >
        Betala med Klarna
      </CustomButton>
    </SubscriptionFormEl>
  );
};

export default SubscriptionForm;
