import styled from "styled-components";
import { respondTo } from "../../styles";

export const ProductOptions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 45px;

  ${respondTo.md`
  flex-direction: row;`}
`;

export const ProductOption = styled.div`
  background-color: var(--infoBg);
  border-radius: 10px;
  width: 100%;
  padding: 26px;

  ${respondTo.md`
  width: 50%;`}

  &:first-of-type {
    margin: 0 0 30px 0;

    ${respondTo.md`
      margin: 0 20px 0 0;;`}
  }
  p {
    padding-left: 35px;
  }

  p:last-of-type {
    margin-bottom: 15px;
  }

  select {
    margin-right: 24px;
  }

  span,
  label {
    font-weight: 600;
  }
`;

export const ProductOptionNew = styled.div`
  background-color: var(--yellowNew);
  border-radius: 10px;
  width: 100%;
  padding: 26px;

  ${respondTo.md`
  width: 50%;`}

  &:first-of-type {
    margin: 0 0 30px 0;

    ${respondTo.md`
      margin: 0 20px 0 0;;`}
  }
  p {
    padding-left: 35px;
  }

  p:last-of-type {
    margin-bottom: 15px;
  }

  select {
    margin-right: 24px;
  }

  span,
  label {
    font-weight: 600;
  }
`;
